import React from 'react';
import Img from 'gatsby-image';
import { useSimpleGalleryQuery } from '../hooks/useSimpleGalleryQuery';

const SimpleGalleryCertification = () => {
  const { certification } = useSimpleGalleryQuery();
  return (
    <section className="container mb-16 lg:mb-28">
      <h2 className="mb-8 text-2xl font-bold text-center lg:text-3xl">
        Registrado en agencias federales
      </h2>
      <div className="flex flex-col items-center md:max-w-2xl md:mx-auto md:flex-wrap md:justify-center lg:max-w-full lg:flex-row">
        {certification.nodes.map(image => (
          <Img
            className="my-4 md:mx-4"
            key={image.id}
            fixed={image.childImageSharp.fixed}
          />
        ))}
      </div>
    </section>
  );
};

export default SimpleGalleryCertification;
