import React from 'react';
import Layout from '../templates/Layout';
import SEO from '../components/SEO';
import HeroDiagonalIndustrial from '../components/HeroDiagonalIndustrial';
import LightBoxGallery from '../components/LightBoxGallery';
import { useGalleryQuery } from '../hooks/useGalleryQuery';
import SimpleGalleryCertification from '../components/SimpleGalleryCertification';
import SimpleGalleryGoverment from '../components/SimpleGalleryGoverment';
import SimpleGalleryProducts from '../components/SimpleGalleryProducts';
import ServiceListCommercial from '../components/ServicesListCommercial';

import Payments from '../components/Payments';

const SelladoTechoComercialIndustrialPage = () => {
  const { industrial, inter, comercial } = useGalleryQuery();
  const indu = industrial.edges.map(({ node }) => node.childImageSharp);
  const int = inter.edges.map(({ node }) => node.childImageSharp);
  const com = comercial.edges.map(({ node }) => node.childImageSharp);

  return (
    <Layout>
      <SEO
        pageTitle="Sellador de techo comercial e industrial en Puerto Rico"
        pageDescription="Le brindamos a nuestros clientes servicio profesional con productos de alta calidad y al mejor precio del mercado."
      />

      <HeroDiagonalIndustrial />

      <ServiceListCommercial />

      <LightBoxGallery
        galleryTitle="Trabajo industrial"
        galleryDescription="A esta industria se le removió un sistema existente dañado por el huracán María. Se utilizó un sistema nuevo e innovador de Danosa, el sistema Loose Laid aprobado por Factory Mutual, en la que la membrana de base solo se entorcha en los solapamientos. Este tipo de membrana es más resistente para huracanes puesto que la fuerza del viento no la desgarra."
        images={com}
      />

      <LightBoxGallery
        galleryTitle="Agencia Federal"
        galleryDescription="Calidad Danosa con sistema de impermeabilización de terminación granulada."
        images={indu}
      />

      <LightBoxGallery
        galleryTitle="Comercial"
        galleryDescription="Sistema de impermeabilización utlizado, Hydro Stop."
        images={int}
      />
      <SimpleGalleryProducts />
      <SimpleGalleryCertification />
      <SimpleGalleryGoverment />
      <Payments />
    </Layout>
  );
};

export default SelladoTechoComercialIndustrialPage;
