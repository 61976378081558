import React from 'react';
import ServiceListItem from './ServiceListItem';

const ServicesList = () => {
  return (
    <section className="mx-8 mb-16 lg:mb-28">
      <div className="container">
        <h2 className="text-2xl font-bold text-center md:text-3xl">
          Servicios
        </h2>
        <ul className="grid max-w-4xl grid-cols-1 mx-auto mt-8 gap-x-8 gap-y-2 md:grid-cols-2">
          <ServiceListItem> Evaluación de techos</ServiceListItem>
          <ServiceListItem> Reparación de superficies</ServiceListItem>
          <ServiceListItem>
            Verificación, reparación e instalación de drenajes
          </ServiceListItem>
          <ServiceListItem>Nivelación de empozamientos de agua</ServiceListItem>
          <ServiceListItem> Reparaciones de grietas</ServiceListItem>
          <ServiceListItem>
            Sellado de cisternas en espacios confinados
          </ServiceListItem>
          <ServiceListItem>Capacitación de Factory Mutual (FM)</ServiceListItem>
          <ServiceListItem> Sellado de contenedores</ServiceListItem>
          <ServiceListItem>Sellado de canchas bajo techo</ServiceListItem>
          <ServiceListItem>
            Sellado de Paredes y pisos por debajo del nivel freático
          </ServiceListItem>
          <ServiceListItem>
            Contamos con plan de seguridad y salud ocupacional de Osha
          </ServiceListItem>
        </ul>
      </div>
    </section>
  );
};

export default ServicesList;
